<div class="container-fluid">
  <div class="mt-4"></div>
  <div class="row justify-content-center align-items-center">

    <div class="col-xl-3 col-lg-3 col-md-3  order-xl-first order-lg-first order-md-first order-1 ">
      <div class="row justify-content-center mb-5">
        <img src="../../../assets/img/f-tl.png" class="img-fluid" alt="...">
      </div>
      <div class="row justify-content-center mb-5">
        <img src="../../../assets/img/f-bl.png" class="img-fluid" alt="...">
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-first">
      <div class="row justify-content-center mb-5">
        <img src="../../../assets/img/f-f2.png" class="img-fluid" alt="...">
      </div>
      <div class="row justify-content-center mb-5">
        <img src="../../../assets/img/f-f2.png" class="img-fluid" alt="...">
      </div>
    </div>

    <div class="col-xl-3 col-lg-3 col-md-3  order-xl-2 order-lg-2 order-2">
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-tr.png" class="img-fluid" alt="...">
      </div>
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-br.png" class="img-fluid" alt="...">
      </div>

    </div>
  </div>
</div>