<div class="container">
  <div class="row">
    <div class="col-md-2">
      <p>
        new-words works!
      </p>
    </div>
    <div class="col-md-10">mud justice charity clinical inequality<br>
      drained<br>
      exhausted<br>
      commitment<br>
      slacking<br>
      jealous<br>
      marches<br>
      drained<br>
      embassies<br>
      particularly<br>
      <br>
    </div>
    <div class="col-md-2">

    </div>
  </div>
</div>