<div class="container-fluid">
  <div class="mt-4"></div>
  <div class="row justify-content-center align-items-center">
    <div class="col-xl-2 col-lg-6 col-md-6 order-xl-2 order-3">

      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-tl.png" class="img-fluid" alt="...">
      </div>
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-bl.png" class="img-fluid" alt="...">
      </div>
    </div>
    <div class="col-xl-8 col-lg-6 col col-md-auto col-12 order-xl-2 order-first mb-5">
      <img src="../../../assets/img/f-f2.png" class="img-fluid" alt="...">
    </div>
    <div class="col-xl-8 col-lg-6 col-md-auto col-12 order-xl-2 order-2 mb-5">
      <img src="../../../assets/img/f-f2.png" class="img-fluid" alt="...">
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col order-xl-3 order-4">
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-tr.png" class="img-fluid" alt="...">
      </div>
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-br.png" class="img-fluid" alt="...">
      </div>

    </div>
  </div>
</div>