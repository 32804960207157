<!-- <div class="container pb-5 pt-5">
  <div class="row text-center">
    <div class="col-12">
      <div class="view overlay zoom">
        <img src="../../../assets/img/sky.png" class="img-fluid" alt="zoom">
        <div class="mask flex-center waves-effect waves-light">
          <p class="white-text"> Утеха е да знаещ, че ние сме безсмъртни същества и гравитираме заедно във вечния път на живота.</p>
        </div>
      </div>
    </div>
  </div>

</div> -->

<div class="container-fluid">
  <a routerLink="/test">
  <img src="../../../assets/img/template.png" class="rounded img-25p img-fluid zoom" alt="...">
  </a>
</div>

<!-- <div class=" container pb-5 pt-5"> -->
<!-- <div class="row container-bottom"> -->
<div class="at-abs-bottom">
  <!-- <div class="text-center"> -->
  <img src="../../../assets/img/plant4.png" class="rounded mx-auto d-block img-auto-width img-fluid zoom" alt="...">
  
  <!-- </div> -->
</div>



<!-- </div> -->