<p class="lead ml-5 mt-3">
  <a routerLink="/home">I've got a little black book with my thoughts in.</a>
</p>
<div></div>
<div class="container-fluid">
  <div class="row justify-content-center align-items-center">
    <div class="col-xl-2 col-lg-6 col-md-6 order-xl-1 order-1">

      <div class="row justify-content-center mb-5">
        <a routerLink="/2-1-2">
          <img src="../../../assets/img/2-1-2.png" class="img-fluid" alt="...">
        </a>
      </div>
      <div class="row justify-content-center mb-5">
        <a routerLink="/2-2-2">
          <img src="../../../assets/img/2-2-2.png" class="img-fluid" alt="...">
        </a>
      </div>
    </div>

    <div class="col-xl-8 col-md-auto col order-xl-2 order-first mb-5">
      <img src="../../../assets/img/f-f2.png" class="img-fluid" alt="...">
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col order-xl-3 order-2">

      <div class="row justify-content-center mb-5">
        <a routerLink="/2-1b1-2">
          <img src="../../../assets/img/21b12.png" class="img-fluid" alt="...">
        </a>
      </div>
      <div class="row justify-content-center mb-5"><img src="../../../assets/img/f-br.png" class="img-fluid" alt="...">
      </div>

    </div>
  </div>
</div>