import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-2-1-2',
  templateUrl: './template-2-1-2.component.html',
  styleUrls: ['./template-2-1-2.component.css']
})
export class Template212Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
