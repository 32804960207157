

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { ContactsComponent } from './components/contacts/contacts.component';
// import { ProjectsComponent } from './components/projects/projects.component';
// import { AboutComponent } from './components/about/about.component';
// import { ConstructionComponent} from './components/construction/construction.component';
// import { PriceComponent } from './components/price/price.component';
// import { FaqComponent } from './components/faq/faq.component';
// import { HomePageComponent } from './components/home-page/home-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { TemplateComponent } from './components/template/template.component';
import { Template212Component } from './components/Templates/template-2-1-2/template-2-1-2.component';
import { Template222Component } from './components/Templates/template-2-2-2/template-2-2-2.component';
import { Template21b12Component } from './components/Templates/template21b12/template21b12.component';
import { NewWordsComponent} from './components/new-words/new-words.component';
import { from } from 'rxjs';

// import { DashboardComponent }   from './dashboard/dashboard.component';
// import { HeroesComponent }      from './heroes/heroes.component';
// import { HeroDetailComponent }  from './hero-detail/hero-detail.component';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: HomePageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'test', component: TemplateComponent },
  { path: '2-1-2', component: Template212Component },
  { path: '2-2-2', component: Template222Component },
  { path: '2-1b1-2', component: Template21b12Component},
  { path: 'new-w', component: NewWordsComponent},
  


  // { path: 'about', component: AboutComponent },
  // { path: 'detail/:id', component: HeroDetailComponent },
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'construction', component: ConstructionComponent },
  // { path: 'price', component: PriceComponent },
  // { path: 'contacts', component: ContactsComponent },
  // { path: 'faq', component: FaqComponent },
  // { path: 'models.html', component: ProjectsComponent },
  // { path: 'prices.html', component: PriceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
