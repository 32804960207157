import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { HomePageComponent } from './components/home-page/home-page.component';
import { TemplateComponent} from './components/template/template.component'
import { Template212Component } from './components/Templates/template-2-1-2/template-2-1-2.component';
import { Template222Component } from './components/Templates/template-2-2-2/template-2-2-2.component';
import { Template21b12Component } from './components/Templates/template21b12/template21b12.component';

import { AppRoutingModule } from './app-route.routing';




// import { DashboardComponent }   from './dashboard/dashboard.component';
// import { HeroesComponent }      from './heroes/heroes.component';
// import { HeroDetailComponent }  from './hero-detail/hero-detail.component';
@NgModule({
  declarations: [
    AppComponent,
    // ConstructionComponent,
    // PriceComponent,
    // ProjectsComponent,
    // ContactsComponent,
    // AboutComponent,
    // FaqComponent,
    HomePageComponent,
    TemplateComponent,
    Template212Component,
    Template222Component,
    Template21b12Component,

    // AngularTextTruncateModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    // FormsModule,
    AppRoutingModule,
    // MatExpansionModule,
    // NgxGalleryModule,
    // MatIconModule,
    // AngularTextTruncateModule,
    // MatGridListModule,
    // NgxViewerModule,
    // HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
