<!-- Start your project here-->
<!--Navbar-->


<router-outlet></router-outlet>

<!-- Footer -->
<footer class="page-footer font-small">

  <div class="text-right  pr-1">
    <!-- <img src="../../../assets/img/email.png" class="rounded" height="33" alt="..."> -->
    <!-- <h6 class="h6-responsive">Go for more...</h6> -->
    <!-- <img src="../../../assets/img/gfmd-red-sm.png" class="rounded" height="20" alt="..."> -->
    <img src="../../../assets/img/steps-br.png" class="rounded" height="35" alt="...">

  </div>
</footer>
<!-- Footer -->